import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _53ffe13b = () => interopDefault(import('../pages/endo.vue' /* webpackChunkName: "pages/endo" */))
const _6fefa29b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _55d7e452 = () => interopDefault(import('../pages/relic/_relic.vue' /* webpackChunkName: "pages/relic/_relic" */))
const _2e924df7 = () => interopDefault(import('../pages/set/_set.vue' /* webpackChunkName: "pages/set/_set" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/endo",
    component: _53ffe13b,
    name: "endo___en"
  }, {
    path: "/es",
    component: _6fefa29b,
    name: "index___es"
  }, {
    path: "/pt",
    component: _6fefa29b,
    name: "index___pt"
  }, {
    path: "/es/endo",
    component: _53ffe13b,
    name: "endo___es"
  }, {
    path: "/pt/endo",
    component: _53ffe13b,
    name: "endo___pt"
  }, {
    path: "/es/relic/:relic?",
    component: _55d7e452,
    name: "relic-relic___es"
  }, {
    path: "/es/set/:set?",
    component: _2e924df7,
    name: "set-set___es"
  }, {
    path: "/pt/relic/:relic?",
    component: _55d7e452,
    name: "relic-relic___pt"
  }, {
    path: "/pt/set/:set?",
    component: _2e924df7,
    name: "set-set___pt"
  }, {
    path: "/relic/:relic?",
    component: _55d7e452,
    name: "relic-relic___en"
  }, {
    path: "/set/:set?",
    component: _2e924df7,
    name: "set-set___en"
  }, {
    path: "/",
    component: _6fefa29b,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
